import Vue from 'vue'
import { EventBus } from './event-bus'

Vue.directive('move-into', {
  inserted (el, b) {
    let paragraphs = document.querySelectorAll(`#${b.value} p`)
    let i = Math.floor(paragraphs.length / 3)
    if (i) {
      paragraphs[i].parentNode.insertBefore(el, paragraphs[i + 1])
    }
  }
})

Vue.directive('prevent-is-vendor', {
  inserted (el) {
    el.addEventListener('click', function (e) {
      if (window.Laravel.isVendor) {
        e.preventDefault()
        EventBus.$emit('add-modal-to-queue', 'modal-is-vendor')
        return false
      }
    })
  }
})