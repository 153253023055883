<template>
  <input :id="id" :value="address" :placeholder="placeholder" :class="classNames" @change="changeInput" @click="clickedInput(true)" v-on:blur="clickedInput(false)">
</template>

<script>
import { filter, each } from 'lodash'

export default {
  props: {
    placeholder: {
      default: null
    },
    value: {
      default: null
    },
    nameOnly: {
      default: false
    },
    classNames: {
      default: null
    }
  },
  data () {
    return {
      id: Math.floor(Math.random() * 1000)
    }
  },
  computed: {
    address () {
      if (this.nameOnly) return this.value.name

      return filter([this.value.name, this.value.region, this.value.city, this.value.state, this.value.country]).join(', ')
    }
  },
  mounted () {
    const autocomplete = new google.maps.places.Autocomplete(document.getElementById(this.id))
    autocomplete.addListener('place_changed', () => {
      this.setAddress(autocomplete.getPlace())
    })
  },

  created () {
    if (!this.value) {
      this.value = {}
      console.log('hi')
    }
  },

  methods: {
    setAddress (address) {
      const addressMappings = {
        locality: 'city',
        country: 'country',
        route: 'region',
        administrative_area_level_1: 'state',
        administrative_area_level_3: 'city',
        sublocality_level_2: 'city'
      }

      const location = {
        city: null,
        country: null,
        country_iso_code: null,
        state: null,
        region: null,
        phone: address.international_phone_number || address.formatted_phone_number || null,
        website: address.website,
        name: address.name || null
      }

      each(address.address_components, item => {
        if (addressMappings.hasOwnProperty(item.types[0])) {
          if (item.types[0] === addressMappings.country){
            location['country_iso_code'] = item.short_name || null
          }
          location[addressMappings[item.types[0]]] = item.long_name || null
        }
      })
      this.$emit('input', location)
    },
    changeInput (event) {
      if (!event.target.value) this.$emit('input', null)
    },
    clickedInput (value) {
      this.$emit('clicked', value)
    }
  }
}
</script>
