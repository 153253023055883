<template>
  <a href="#" @click.prevent="openDialog">
    <span v-if="inProgress">Loading...</span>
    <slot v-else>Upload New Picture</slot>
  </a>
</template>

<script>
import { each } from 'lodash'
import uploadcare from 'uploadcare-widget'

export default {
  props: ['value', 'crop', 'multiple', 'notImagesOnly', 'imageShrink'],
  data () {
    return {
      inProgress: false
    }
  },
  methods: {
    openDialog (event) {
      if (event) event.preventDefault()
      if (this.inProgress) return false
      let dialog = uploadcare.openDialog(null, {
        imagesOnly: !this.notImagesOnly,
        crop: this.crop,
        multiple: this.multiple,
        imageShrink: this.imageShrink ? this.imageShrink : '1280x1024',
        validators: [
          function (fileInfo) {
            if (fileInfo.size !== null && fileInfo.size > 1024 * 1024) {
              throw new Error('fileMaximumSize')
            }
          }
        ]
      })
      dialog.done(file => {
        this.inProgress = true

        if (file.files) { // multiple
          let files = []
          let filesInfo = []

          each(file.files(), file => {
            file.done(info => {
              files.push(info.cdnUrl)
              filesInfo.push(info)
            })
          })
          file.promise().done(() => {
            this.inProgress = false
            this.$emit('input', files)
            this.$emit('done', files)

            this.$emit('upload', filesInfo)
          })
        } else {
          file.done(info => {
            this.inProgress = false
            this.$emit('input', info.cdnUrl)
            this.$emit('done', info.cdnUrl)

            this.$emit('upload', info)
          })
        }
      })
    }
  }
}
</script>